<template>
    <v-navigation-drawer
        v-model="active"
        right
        temporary
        fixed
        width="600"
    >
        <v-container class="pa-10" v-if="inventory">
            <v-row align="center">
                <v-col>
                    <v-row>
                        <v-col v-if="inventory.id" class="py-0">
                            <h4 class="headline blue-grey--text text--darken-3">{{ inventory.name }}
                                <span class="text--lighten-1 grey--text">#{{ inventory.id }}</span>
                            </h4>
                        </v-col>

                        <v-col v-else class="py-0">
                            <h4 class="headline blue-grey--text text--darken-3">Crear nuevo item</h4>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row>
                <v-col cols="12">
                    <v-form>
                        <v-text-field
                            v-model="fields.name"
                            label="Nombre"
                            required
                        ></v-text-field>
                    </v-form>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green darken-1" text @click="onCancelSelected">Cancelar</v-btn>
                <v-btn color="primary" @click="onSaveSelected">Guardar</v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    export default {
        components: {
        },

        data() {
            return {
                active: false,

                fields: {}
            };
        },

        props: {
            value: {
                type: Boolean,
                default: false
            },

            inventory: {
                type: Object
            }
        },

        computed: {
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            },

            inventory: {
                handler(val) {
                    this.fields = { ...val };
                },
                immediate: true
            }
        },

        methods: {
            async onSaveSelected() {
                let payload = { ...this.fields };

                try {
                    if (this.inventory.id) {
                        await this.$store.dispatch('inventory/patch', { inventory: this.inventory, data: payload });
                    }
                    else {
                        await this.$store.dispatch('inventory/create', { data: payload });
                    }

                    this.active = false;
                }
                catch (err) {
                    console.error(err);
                }
            },

            onCancelSelected(value) {
                this.active = false;
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
