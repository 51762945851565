<template>
    <v-autocomplete
        ref="field"
        v-model="provider"
        :items="providers"
        :loading="isLoading"
        hide-no-data
        hide-selected
        item-text="name"
        label="Proveedor"
        placeholder=""
        return-object
        auto-select-first
    >
        <template v-slot:item="item">
            <v-list-item-content>
                <v-list-item-title v-text="item.item.name"></v-list-item-title>
                <!-- <v-list-item-subtitle v-text="item.item.presentation"></v-list-item-subtitle> -->
            </v-list-item-content>
        </template>
    </v-autocomplete>
</template>

<script>
    export default {
        components: {},

        props: {
            value: Object
        },

        data() {
            return {
                provider: null
            };
        },

        computed: {
            providers() {
                return this.$store.getters['providers/getAll']();
            },

            isLoading() {
                return this.$store.getters['providers/isLoading']();
            }
        },

        mounted() {

        },

        watch: {
            value(v) {
                this.provider = v;
            },

            provider(v) {
                this.$emit('input', v);
            }
        },

        methods: {
            focus() {
                this.$refs.field.focus();
            }
        }
    };
</script>

<style lang="scss" scoped>

</style>
