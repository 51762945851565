var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('template-layout',[_c('template',{slot:"drawers"},[_c('inventory-edit-drawer',{attrs:{"inventory":_vm.selectedInventory},model:{value:(_vm.showEditDrawer),callback:function ($$v) {_vm.showEditDrawer=$$v},expression:"showEditDrawer"}}),_c('inventory-ingress-drawer',{model:{value:(_vm.showIngressDrawer),callback:function ($$v) {_vm.showIngressDrawer=$$v},expression:"showIngressDrawer"}}),_c('inventory-egress-drawer',{model:{value:(_vm.showEgressDrawer),callback:function ($$v) {_vm.showEgressDrawer=$$v},expression:"showEgressDrawer"}})],1),_c('template',{slot:"content"},[_c('v-container',[_c('v-toolbar',{staticClass:"mb-2",attrs:{"flat":"","dense":"","color":"transparent","height":"60"}},[_c('v-toolbar-title',[_c('span',{staticClass:"headline"},[_vm._v("Inventario")])]),_c('v-spacer'),_c('v-toolbar-title',[_c('v-text-field',{staticClass:"mr-4",staticStyle:{"max-width":"180px"},attrs:{"label":"Buscar...","color":"primary","dense":"","hide-details":"","flat":"","prepend-icon":"mdi-magnify","clearable":""},model:{value:(_vm.search),callback:function ($$v) {_vm.search=$$v},expression:"search"}})],1),_c('v-toolbar-title',[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","icon":""},on:{"click":function($event){_vm.showIngressDrawer = true}}},on),[_c('v-icon',[_vm._v("mdi-arrow-up")])],1)]}}])},[_c('span',[_vm._v("Ingreso de Mercaderia")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","icon":""},on:{"click":function($event){_vm.showEgressDrawer = true}}},on),[_c('v-icon',[_vm._v("mdi-arrow-down")])],1)]}}])},[_c('span',[_vm._v("Descarte de Mercaderia")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","icon":"","to":{ name: 'process' }}},on),[_c('v-icon',[_vm._v("mdi-cog-outline")])],1)]}}])},[_c('span',[_vm._v("Procesar")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({attrs:{"fab":"","small":"","icon":"","to":{ name: 'inventoryMovementsList' }}},on),[_c('v-icon',[_vm._v("mdi-swap-vertical")])],1)]}}])},[_c('span',[_vm._v("Ver Movimientos")])]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
var on = ref.on;
return [_c('v-btn',_vm._g({staticClass:"elevation-0",attrs:{"fab":"","x-small":"","color":"primary"},on:{"click":_vm.onCreateSelected}},on),[_c('v-icon',[_vm._v("mdi-plus")])],1)]}}])},[_c('span',[_vm._v("Nuevo item")])])],1)],1),_c('v-row',[_c('v-col',{attrs:{"cols":"12"}},[_c('v-card',[_c('v-card-text',[_c('v-data-table',{attrs:{"hide-default-footer":"","hide-default-header":"","items":_vm.inventoryItems,"loading":_vm.isLoading,"headers":_vm.headers,"search":_vm.search,"items-per-page":-1,"sort-by":"name"},scopedSlots:_vm._u([{key:"item.id",fn:function(ref){
var item = ref.item;
return [_vm._v(" # "+_vm._s(item.id)+" ")]}},{key:"item.currentStock",fn:function(ref){
var item = ref.item;
return [_c('v-icon',[_vm._v("mdi-package-variant-closed")]),_vm._v(" "+_vm._s(item.currentStock)+" ")]}},{key:"item.actions",fn:function(ref){
var item = ref.item;
return [_c('v-btn',{attrs:{"icon":"","small":""},on:{"click":function($event){$event.stopPropagation();return _vm.onInventoryItemSelected(item)}}},[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}])})],1)],1)],1)],1)],1)],1)],2)}
var staticRenderFns = []

export { render, staticRenderFns }