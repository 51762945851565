<template>
    <template-layout>
        <template slot="drawers">
            <inventory-edit-drawer
                v-model="showEditDrawer"
                :inventory="selectedInventory"
            ></inventory-edit-drawer>

            <inventory-ingress-drawer
                v-model="showIngressDrawer"
            />

            <inventory-egress-drawer
                v-model="showEgressDrawer"
            />
        </template>

        <template slot="content">
            <v-container>
                <v-toolbar flat dense color="transparent" height="60" class="mb-2">
                    <v-toolbar-title>
                        <span class="headline">Inventario</span>
                    </v-toolbar-title>

                    <v-spacer></v-spacer>

                    <v-toolbar-title>
                        <v-text-field
                            class="mr-4"
                            v-model="search"
                            label="Buscar..."
                            color="primary"
                            dense hide-details flat prepend-icon="mdi-magnify"
                            clearable
                            style="max-width: 180px;"
                        />
                    </v-toolbar-title>

                    <v-toolbar-title>
                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <!-- <v-btn fab x-small color="primary" class="elevation-0" @click="onCreateSelected" v-on="on"><v-icon>mdi-plus</v-icon></v-btn> -->
                                <v-btn fab small icon @click="showIngressDrawer = true" v-on="on"><v-icon>mdi-arrow-up</v-icon></v-btn>
                            </template>
                            <span>Ingreso de Mercaderia</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <!-- <v-btn fab x-small color="primary" class="elevation-0" @click="onCreateSelected" v-on="on"><v-icon>mdi-plus</v-icon></v-btn> -->
                                <v-btn fab small icon @click="showEgressDrawer = true" v-on="on"><v-icon>mdi-arrow-down</v-icon></v-btn>
                            </template>
                            <span>Descarte de Mercaderia</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <!-- <v-btn fab x-small color="primary" class="elevation-0" @click="onCreateSelected" v-on="on"><v-icon>mdi-plus</v-icon></v-btn> -->
                                <v-btn fab small icon :to="{ name: 'process' }" v-on="on"><v-icon>mdi-cog-outline</v-icon></v-btn>
                            </template>
                            <span>Procesar</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <!-- <v-btn fab x-small color="primary" class="elevation-0" @click="onCreateSelected" v-on="on"><v-icon>mdi-plus</v-icon></v-btn> -->
                                <v-btn fab small icon :to="{ name: 'inventoryMovementsList' }" v-on="on"><v-icon>mdi-swap-vertical</v-icon></v-btn>
                            </template>
                            <span>Ver Movimientos</span>
                        </v-tooltip>

                        <v-tooltip bottom>
                            <template v-slot:activator="{ on }">
                                <v-btn fab x-small color="primary" class="elevation-0" @click="onCreateSelected" v-on="on"><v-icon>mdi-plus</v-icon></v-btn>
                            </template>
                            <span>Nuevo item</span>
                        </v-tooltip>
                    </v-toolbar-title>
                </v-toolbar>

                <v-row>
                    <v-col cols="12">
                        <v-card>
                            <v-card-text>
                                <v-data-table
                                    hide-default-footer
                                    hide-default-header
                                    :items="inventoryItems"
                                    :loading="isLoading"
                                    :headers="headers"
                                    :search="search"
                                    :items-per-page="-1"
                                    sort-by="name"
                                >
                                    <template v-slot:item.id="{ item }">
                                        # {{ item.id }}
                                    </template>

                                    <template v-slot:item.currentStock="{ item }">
                                        <v-icon>mdi-package-variant-closed</v-icon>

                                        {{ item.currentStock }}
                                    </template>

                                    <template v-slot:item.actions="{ item }">
                                        <v-btn icon small @click.stop="onInventoryItemSelected(item)">
                                            <v-icon>mdi-pencil</v-icon>
                                        </v-btn>
                                    </template>
                                </v-data-table>
                            </v-card-text>
                        </v-card>
                    </v-col>
                </v-row>
            </v-container>
        </template>
    </template-layout>
</template>

<script>
    import TemplateLayout from '@/layout/TemplateLayout';
    import InventoryEditDrawer from './InventoryEditDrawer.vue';
    import InventoryIngressDrawer from './InventoryIngressDrawer.vue';
    import InventoryEgressDrawer from './InventoryEgressDrawer.vue';

    export default {
        components: {
            TemplateLayout,
            InventoryEditDrawer,
            InventoryIngressDrawer,
            InventoryEgressDrawer
        },

        data() {
            return {
                showEditDrawer: false,
                showCreateDrawer: false,
                showIngressDrawer: false,
                showEgressDrawer: false,

                selectedInventory: null,

                defaultFilters: {},
                filters: {},

                search: '',

                pagination: {
                    page: 1,
                    itemsPerPage: 100
                },

                headers: [
                    { text: 'Código', value: 'id' },
                    { text: 'Nombre', value: 'name' },
                    { text: 'Stock', value: 'currentStock' },
                    { text: 'Acciones', value: 'actions', align: 'right' }
                ]
            };
        },

        computed: {
            isLoading() {
                return this.$store.getters['inventory/isLoading']();
            },

            totalInventoryItems() {
                return this.$store.getters['inventory/getTotal']();
            },

            inventoryItems() {
                return this.$store.getters['inventory/getAll']();
            }
        },

        watch: {
            pagination: {
                handler(val) {
                    this.updateRouteQuery();
                },
                deep: true
            },

            filters: {
                handler(val) {
                    this.updateRouteQuery();

                    this.fetchInventory();
                },

                deep: true
            }
        },

        mounted() {
            this.filters = JSON.parse(JSON.stringify(this.defaultFilters));

            this.updateFiltersFromQuery();
            // this.updateRouteQuery();

            this.$store.dispatch('providers/fetchAll');
        },

        methods: {
            updateFiltersFromQuery() {
                let q = { ...this.$route.query };

                for(let key of Object.keys(this.filters)) {
                    if(q[key]) {
                        this.filters[key] = q[key];
                    }
                }

                if(this.$route.query.search) {
                    this.search = this.$route.query.search;
                }
            },

            updateRouteQuery() {
                let q = {};

                q = { ...this.filters };

                if(this.search) {
                    q.search = this.search;
                }

                this.$router.replace({ name: 'inventoryList', query: q }).catch(() => {});
            },

            async fetchInventory() {
                let filters = {};
                // filters.offset = 0;
                // filters.limit = 1000;

                await this.$store.dispatch('inventory/fetchAll', filters);
            },

            onInventoryItemSelected(inventory) {
                this.showEditDrawer = true;
                this.selectedInventory = inventory;
            },

            onCreateSelected() {
                this.showEditDrawer = true;
                this.selectedInventory = {};
            }
        }
    };
</script>

<style lang="scss" scoped>
</style>
