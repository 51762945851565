<template>
    <v-navigation-drawer
        v-model="active"
        right
        fixed
        temporary
        width="600"
    >
        <v-container v-if="active" class="pa-10">
            <v-row>
                <v-col>
                    <v-row>
                        <v-col class="py-0">
                            <span class="headline blue-grey--text text--darken-3">Egreso de mercaderia</span>
                        </v-col>
                    </v-row>
                </v-col>
            </v-row>

            <v-row class="pb-12">
                <v-col cols="12">
                    <inventory-item-select
                        v-model="selectedItem"
                    />

                    <v-text-field
                        ref="quantityField"
                        label="Cantidad"
                        v-model="quantity"
                        @focus="$event.target.select()"
                    ></v-text-field>

                    <v-text-field
                        label="Motivo"
                        v-model="comment"
                    ></v-text-field>
                </v-col>
            </v-row>
        </v-container>

        <template v-slot:append>
            <v-row class="ma-4">
                <v-spacer></v-spacer>

                <v-btn color="green" text @click="onCancelSelected">
                    Cancelar
                </v-btn>

                <v-btn color="green" dark @click="onConfirmSelected" :loading="isLoading">
                    Aceptar
                </v-btn>
            </v-row>
        </template>
    </v-navigation-drawer>
</template>

<script>
    import InventoryItemSelect from '@/components/Production/InventoryItemSelect';

    export default {
        components: {
            InventoryItemSelect
        },

        props: {
            value: {
                type: Boolean,
                default: false
            }
        },

        data() {
            return {
                active: false,

                selectedItem: null,
                quantity: null,
                comment: ''
            };
        },

        computed: {
            isLoading() {
                return false; // return this.$store.getters['stock/isLoading']();
            },

            isAddDisabled() {
                return !this.quantity || !this.selectedItem;
            }
        },

        watch: {
            active(val) {
                this.$emit('input', val);
            },

            value(val) {
                this.active = val;
            }
        },

        methods: {
            async onConfirmSelected(value) {
                let data = {
                    comment: this.comment,
                    item: this.selectedItem.id,
                    quantity: -Math.abs(parseInt(this.quantity))
                };

                await this.$store.dispatch('inventory/egress', data);

                this.active = false;
            },

            onCancelSelected(value) {
                this.active = false;
            }
        }
    };
</script>
